import apiClient from '../server/api.config';

export interface Privilege {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  name: string;
  description: string;
  advantage: string;
  is_active: boolean;
  employees: Array<Employee>;
  pass_id: string;
  image_url: string;
}

interface Employee {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  identity_card_id: string | null;
  first_name: string;
  last_name: string;
  email: string;
  start_date: string;
  end_date: string | null;
  phone: string | null;
  address: string | null;
  city: string | null;
  country: string | null;
  image_url: string;
  birth_date: string;
  active: boolean;
  privileges: Array<Privilege>;
}

interface EmployeeResponse {
  record: Employee;
}

export const getEmployeeById = async (employeeId: string) => {
  const response = await apiClient.get<EmployeeResponse>(
    `/employee/${Number(employeeId)}`
  );
  return response.data.record;
};
