import axios from "axios";
import { QueryClient } from "@tanstack/react-query";

const BASE_URL = process.env.REACT_APP_HOST_URL;

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const getAccessToken = async (): Promise<string> => {
  const token = localStorage.getItem("token");
  if (token) return token;

  const response = await axios.post(
    `${BASE_URL}/oauth/token`,
    {},
    {
      auth: {
        username: process.env.REACT_APP_AUTH_USERNAME as string,
        password: process.env.REACT_APP_AUTH_PASSWORD as string,
      },
    }
  );

  return response.data.record.access_token;
};

apiClient.interceptors.request.use(async (config) => {
  const token = await getAccessToken();

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
    localStorage.setItem("token", token);
  }

  return config;
});

export const queryClient = new QueryClient();

export default apiClient;
