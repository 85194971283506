import "../styles/NotFound.css";

const NotFound = () => {
  return (
    <div className="not-found-container">
      <h1 className="error-code">404</h1>
      <p className="error-message">
        Oops! The page you're looking for doesn't exist.
      </p>
      <p className="redirect-message">
        You will be redirected to the homepage shortly.
      </p>
      <a href="/" className="home-link">
        Go to Homepage
      </a>
    </div>
  );
};

export default NotFound;
