import { memo } from "react";
import { Privilege } from "../services/EmployeeService";
import PrivilegeCard from "../components/PrivilegeCard/PrivilegeCard";
import './PrivilegeList.css';

type Props = {
  privileges: Privilege[]
  isLoading: boolean;
  removePrivilege: (privilegeId: number) => void;
}

const PrivilegesList = ({ privileges, isLoading, removePrivilege }: Props) => {
  return (
    <div className="privilege-section">
      <div className="title-container">
        <p className="main-title">Choose your Privilege</p>
        <p className="description">Click to access to wallet link</p>
      </div>
      {isLoading ? <p>...אנא המתן</p> :
        privileges.length ?
          <div className="grid-container">
            {privileges.map(privilege => <PrivilegeCard key={privilege.id} privilege={privilege} removePrivilege={removePrivilege} />)}
          </div>
          :
          <p>!לא נותרו עוד כרטיסים</p>
      }
    </div>
  )
}

export default memo(PrivilegesList);
