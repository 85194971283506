import ExpiredPassKit from "../assets/expiredOffer.png";
import { useQRCodeStatus } from "../services/QRCodeService";
import "../App.css";
import { useLocation } from "react-router-dom";

const useQuery = () => {
  const { search } = useLocation();
  return new URLSearchParams(search);
};

const PassDataChecker: React.FC = () => {
  const queryParams = useQuery();
  const pass_id = queryParams.get("code") || "";

  const { data, error, isLoading } = useQRCodeStatus(pass_id);

  if (!pass_id)
    return (
      <div>
        <img src={ExpiredPassKit} alt="Expired Offer" />
        <p>No pass code provided</p>
      </div>
    );

  if (isLoading || !data) return <p>We are checking the pass code...</p>;

  if (error) {
    console.error("Error checking pass code:", error);
    return <p>Error checking pass code</p>;
  }

  if (data.status === "valid") {
    window.location.href = `${process.env.REACT_APP_REDIRECT_PASS_URL}/${pass_id}`;
  } else {
    return (
      <div className="invalid-offer-container">
        <img src={ExpiredPassKit} alt="Expired Offer" />
        <p>Expired offer</p>
        <span>Unfortunately, this offer is no longer available.</span>
      </div>
    );
  }
  return null;
};

export default PassDataChecker;
