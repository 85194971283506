// src/components/QRCodeChecker.tsx
import React from "react";
import { useLocation } from "react-router-dom";
import { useQRCodeStatus } from "../services/QRCodeService";
import expiredOffer from "../assets/expiredOffer.png";
import validOffer from "../assets/validOffer.png";
import "../App.css";

const useQuery = () => {
  const { search } = useLocation();
  return new URLSearchParams(search);
};

// const QRCodeConfig = {
//   valid: {
//     color: "green",
//     text: "Your QR code is valid",
//     component: FaCheckCircle,
//   },
//   invalid: {
//     color: "red",
//     text: "Your QR code is invalid",
//     component: FaTimesCircle,
//   },
// };

const QRCodeChecker: React.FC = () => {
  const query = useQuery();
  const code = query.get("code") ?? "";

  const { data, error, isLoading } = useQRCodeStatus(code);

  if (!code) return <p>No QR code found</p>;

  if (isLoading || !data) return <p>Loading...</p>;

  if (error) {
    console.error("Error checking QR code:", error);
    return <p>Error checking QR code</p>;
  }

  if (data.status !== "valid") {
    return (
      <div className="valid-offer-container">
        <img src={validOffer} alt="Valid Offer" />
        <p>Valid offer</p>
        <span>This customer is eligible for this promotional offer.</span>
      </div>
    );
  } else if (data.status === "valid") {
    return (
      <div className="invalid-offer-container">
        <img src={expiredOffer} alt="Expired Offer" />
        <p>Expired offer</p>
        <span>
          Unfortunately, this customer is no longer eligible for this
          promotional offer.
        </span>
      </div>
    );
  }

  return null;
};
// const { component: IconComponent, color, text } = QRCodeConfig[data?.status];

// return (
//   <div className="qrcode-status__check">
//     <p>
//       <IconComponent color={color} />
//       <br />
//       {text}
//     </p>
//   </div>
// );

export default QRCodeChecker;
