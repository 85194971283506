// src/Routes.tsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import QRCodeChecker from "./components/QRCodeChecker";
import PassKitChecker from "./components/PassKitChecker";
import NotFound from "./components/NotFoundPage";
import PrivilegePage from "./components/PrivilegePage/PrivilegePage";

const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/:employeeId" element={<PrivilegePage />} />
        <Route path="/pass/verify" element={<PassKitChecker />} />
        <Route path="/qrcode/verify" element={<QRCodeChecker />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
