// src/services/qrcodeService.ts
import apiClient from "../server/api.config";
import { useQuery } from "@tanstack/react-query";


interface QRCodeCheckResponse {
  record: {
    status: "valid" | "invalid";
  };
}

const fetchQRCodeStatus = async (
  code: string
): Promise<QRCodeCheckResponse["record"]> => {
  const response = await apiClient.get<QRCodeCheckResponse>("/qrcode/verify", {
    params: {
      code,
    },
  });
  return response.data.record;
};

export const useQRCodeStatus = (code: string) => {
  return useQuery({
    queryKey: ["qrcodeStatus", code],
    queryFn: () => fetchQRCodeStatus(code),
    enabled: !!code,
    retry: 3,
  });
};
