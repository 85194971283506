import { memo } from "react";
import { Privilege } from "../../services/EmployeeService";
import { Link } from "react-router-dom";
import './PrivilegeCard.css';

type Props = {
  privilege: Privilege;
  removePrivilege: (privilegeId: number) => void;
}

const PrivilegeCard = ({ privilege, removePrivilege }: Props) => {
  const { id, name, image_url, pass_id } = privilege;
  const link = `/pass/verify?code=${pass_id}`;

  const onClickCardHandler = () => {
    removePrivilege(id);
    window.open(link, "_blank", "noopener, noreferrer");
  }

  const onClickLinkHandler = () => {
    removePrivilege(id);
  }

  return (
    <div style={{ backgroundImage: `url(${image_url})` }} className="card" onClick={onClickCardHandler}>
      <div className="card-content">
        <p className="card-title">{name}</p>
        <Link
          className="link"
          to={link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onClickLinkHandler}
        >
          Click to wallet
        </Link>
      </div>
    </div>
  )
}

export default memo(PrivilegeCard);
